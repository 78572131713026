import { createLoopTheme } from "@loop-brasil/material";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import SnackbarProvider from "components/SnackbarProvider";
import { SessionProvider } from "next-auth/react";
import { useRef } from "react";
import { Hydrate, QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

const theme = createLoopTheme();
const HALF_HOUR = 60 * 30;

const AppProvider = ({ session, dehydratedState, children }) => {
  const queryClientRef = useRef();
  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          retry: false,
        },
      },
    });
  }

  return (
    <QueryClientProvider client={queryClientRef.current}>
      <ReactQueryDevtools initialIsOpen={false} />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SessionProvider
          refetchOnWindowFocus={false}
          refetchInterval={HALF_HOUR}
          session={session}
        >
          <Hydrate state={dehydratedState}>{children}</Hydrate>
          <SnackbarProvider />
        </SessionProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default AppProvider;
