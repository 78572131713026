import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { Button, InputPassword, InputText, Modal } from "@loop-brasil/material";
import Link from "next/link";
import { signIn } from "next-auth/react";

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightBold,
    cursor: "pointer",
    marginTop: theme.spacing(1),
    color: theme.palette.secondary.dark,
    textDecoration: "none",
    fontSize: "1em",
  },
  coloredLink: {
    color: theme.palette.primary.main,
  },
  divider: {
    width: "100%",
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.grey[500],
  },
  marginTop: {
    marginTop: theme.spacing(1),
  },
}));

const LoginModal = ({ isOpen, onClose }) => {
  const classes = useStyles();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [showResendLink, setShowResendLink] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = async (data) => {
    const username = data.email.trim();
    const password = data.password.trim();

    const res = await signIn("credentials-auth", {
      username,
      password,
      redirect: false,
    });

    if (!res) return;

    if (res.ok) {
      onClose();
    }

    if (res.error) {
      const { error, type } = JSON.parse(res.error);
      if (type === "unverified_email") {
        setShowResendLink(true);
      }
      setErrorMessage(error);
    }
  };

  return (
    <Modal
      closeButton={() => onClose()}
      onClose={() => onClose()}
      open={isOpen}
      size="sm"
      title="Login"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography>Faça login para participar dos eventos.</Typography>

        <Grid container className={classes.input}>
          <InputText
            fullWidth
            label="E-mail ou CPF/CNPJ"
            helperText={errors.email?.message}
            {...register("email", {
              required: "Insira um e-mail válido",
            })}
          />
        </Grid>

        <Grid container className={classes.input}>
          <InputPassword
            label="Senha"
            error={Boolean(errors.password)}
            helperText={errors.password?.message}
            {...register("password", {
              required: "O campo senha não pode estar vazio",
            })}
          />
        </Grid>

        <Grid container>
          <Link href="/esqueci-minha-senha">
            <a className={`${classes.link} ${classes.coloredLink}`}>
              Esqueci minha senha
            </a>
          </Link>
        </Grid>

        <Grid item className={classes.marginTop}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            type="submit"
            disableElevation
          >
            Entrar
          </Button>
        </Grid>

        <hr className={classes.divider} />

        <Typography align="center">Não tem cadastro?</Typography>
        <Typography align="center">
          <Link href="/cadastro/37053ded-f261-4769-b78f-43ed366dbcde">
            <a className={classes.link}>Clique aqui</a>
          </Link>{" "}
          para se cadastrar!
        </Typography>
      </form>
    </Modal>
  );
};

export default LoginModal;
