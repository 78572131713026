import { instance } from "api";
import { useAuth } from "hooks/useAuth";
import { useQuery } from "react-query";

export const GET_BOOKMARK_LIST_QUERY_KEY = "GET_BOOKMARK_LIST";

export const getBookmarkList = () =>
  instance.get("/bookmarks").then((res) => res.data);

export const useBookmarkListQuery = () => {
  const { isAuthenticated } = useAuth();

  return useQuery({
    queryKey: [GET_BOOKMARK_LIST_QUERY_KEY],
    queryFn: getBookmarkList,
    enabled: isAuthenticated,
  });
};
