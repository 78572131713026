import React from "react";
import Script from "next/script";

const AppScripts = () => {
  const prod = process.env.NODE_ENV === "production";

  if (!prod) return <></>;

  return (
    <>
      <Script
        id="hotjar-script-loader"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
        (function(h,o,t,j,a,r) {
          h.hj=h.hj || function(){
              (h.hj.q=h.hj.q||[]).push(arguments)
            };
            h._hjSettings={hjid:2485443,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');
            r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
      `,
        }}
      />
      <Script
        strategy="afterInteractive"
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js-na1.hs-scripts.com/23363761.js"
      />
    </>
  );
};

export default AppScripts;
