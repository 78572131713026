import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import {
  GET_BOOKMARK_LIST_QUERY_KEY,
  useBookmarkListQuery,
} from "api/getBookmarkList";
import { postBookmark } from "api/postBookmark";
import { deleteBookmark } from "api/deleteBookmark";

export const useBookmark = () => {
  const queryClient = useQueryClient();

  const bookmarkList = useBookmarkListQuery();

  const onSuccess = useCallback(
    () => queryClient.invalidateQueries([GET_BOOKMARK_LIST_QUERY_KEY]),
    [queryClient]
  );

  const deleteBookmarkMutation = useMutation({
    mutationFn: (sku) => deleteBookmark(sku),
    onSuccess,
  });

  const postBookmarkMutation = useMutation({
    mutationFn: (sku) => postBookmark(sku),
    onSuccess,
  });

  return {
    data: bookmarkList.data ?? [],
    isLoading:
      bookmarkList.isLoading ||
      postBookmarkMutation.isLoading ||
      deleteBookmarkMutation.isLoading,
    add: postBookmarkMutation.mutate,
    remove: deleteBookmarkMutation.mutate,
  };
};
