import dynamic from "next/dynamic";
import Header from "./Header";
import { useRouter } from "next/router";

const DynamicPolicyCookies = dynamic(() => import("./PolicyCookies"), {
  ssr: false,
});
const DynamicFooter = dynamic(() => import("./Footer"), { ssr: false });

const AppTemplate = ({ children }) => {
  const router = useRouter();

  return (
    <>
      <Header isVehiclePage={router.pathname.includes("veiculo")} />
      {children}
      <DynamicPolicyCookies />
      <DynamicFooter />
    </>
  );
};

export default AppTemplate;
