import axios from "axios";

export const instance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  headers: {
    Authorization: "Bearer " + process.env.NEXT_PUBLIC_API_KEY,
  },
});

export const contentManagerInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_CONTENT_MANAGER,
});

export const sigInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_SIG_WEB,
});

export const nextApiInstance = axios.create({
  baseURL: "/api",
});

export const postAuth = (username, password) =>
  instance.post("/account/auth", { username, password, store: "revenda" });

export const postResendConfirmation = (email) =>
  instance.post(`/auth/confirmation/resend`, { email });

export const postConsent = (origin) =>
  instance.post(`/auth/policy-consent`, origin);

export const getStoreById = (id) =>
  instance.get(`/scheduling/store/${id}`).then((res) => res.data);

export const postDocumentOrder = (orderId, formData) =>
  instance.post(`/order/${orderId}/payment/receipt`, formData);

export const getVehicle = (sku) =>
  instance.get(`/vehicle/revenda/${sku}`).then((res) => res.data);

export const postSearchVehicle = (index, params) =>
  instance.post(`/search/${index}`, params);

export const getOffers = (partner, vehicleId) =>
  instance.get(`/order/store/${partner}/product/${vehicleId}/best-offer`);

export const getCustomers = (partner) =>
  instance.get(`/seller/${partner}/customers`);

export const putOrderCustomer = (orderId, customerId) =>
  instance.put(`/order/${orderId}/customer/${customerId}`);

export const getOrder = (orderId) =>
  orderId ? instance.get(`/order/${orderId}`).then((res) => res.data) : null;

export const verifyPasswordRecoveryToken = (token) =>
  instance.get(`/registration/password-recovery/${token}`);

export const putPasswordChange = (token, password) =>
  instance.put(`/registration/password-recovery/${token}/change-password`, {
    password,
  });

export const postPasswordRecovery = (email) =>
  instance.post(`/registration/password-recovery`, { email });

export const getAddress = (zipcode) =>
  axios.get(`https://viacep.com.br/ws/${zipcode}/json/`);

export const getAvailableDates = (storeId) =>
  instance.get(`/scheduling/${storeId}/dates`).then((res) => res.data);

export const getAvailableTimes = (storeId, chosenDate) =>
  instance
    .get(`/scheduling/${storeId}/times/${chosenDate}`)
    .then((res) => res.data);

export const postScheduleVehiclePickup = (data) =>
  instance.post(`/scheduling/withdrawal`, data);

export const putScheduleVehiclePickup = (data) =>
  instance
    .put(`/scheduling/withdrawal/${data.sku}`, data)
    .then((res) => res.data);
