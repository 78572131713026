import { useRouter } from "next/router";
import { useEffect } from "react";

const usePageChange = (cb) => {
  const router = useRouter();

  useEffect(() => {
    router.events.on("routeChangeComplete", cb);
    return () => {
      router.events.off("routeChangeComplete", cb);
    };
  }, [router.events, cb]);
};

export default usePageChange;
