import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { resolveValue, useToaster } from "react-hot-toast/headless";

const typeMap = {
  success: "success",
  error: "error",
  blank: "info",
  loading: "info",
  custom: "info",
};

const SnackbarProvider = () => {
  const { toasts } = useToaster();

  return (
    <div>
      {toasts
        .filter((t) => t.visible)
        .map((t, i) => {
          return (
            <Snackbar
              sx={{ transform: `translateY(-${i * 64}px)`, zIndex: 9999 - i }}
              key={t.id}
              open={t.visible}
              autoHideDuration={t.duration}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              {...t.ariaProps}
            >
              <Alert elevation={6} severity={typeMap[t.type]}>
                {resolveValue(t.message, t)}
              </Alert>
            </Snackbar>
          );
        })}
    </div>
  );
};

export default SnackbarProvider;
