import React from "react";
import Head from "next/head";
import * as Sentry from "@sentry/node";
import AppProvider from "components/App/AppProvider";
import AppScripts from "components/App/AppScripts";
import AppTemplate from "components/App/AppTemplate";
import "global.css";

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
  });
}

const LoopCarros = ({
  Component,
  pageProps: { session, ...pageProps },
  err,
}) => {
  const getLayout = Component.getLayout || null;

  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Loop Revenda" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="description"
          content="Abasteça seu estoque com veículos de qualidade e bom preço no looprevenda.com.br"
        />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <AppScripts />
      <AppProvider
        session={session}
        dehydratedState={pageProps.dehydratedState}
      >
        {!getLayout ? (
          <AppTemplate>
            <Component {...pageProps} err={err} />
          </AppTemplate>
        ) : (
          <Component {...pageProps} err={err} />
        )}
      </AppProvider>
    </>
  );
};

export default LoopCarros;
