import { React, useEffect, useState } from "react";
import Link from "next/link";
import { signOut } from "next-auth/react";
import { Header as LoopHeader } from "@loop-brasil/material";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PersonIcon from "@material-ui/icons/Person";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import FavoriteOutlinedIcon from "@material-ui/icons/FavoriteOutlined";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import { useAuth } from "hooks/useAuth";
import LoginModal from "components/Login/LoginModal";
import usePageChange from "hooks/usePageChange";
import { useBookmark } from "hooks/useBookmark";

const LinkAdapter = ({ href, target, children }) => {
  if (href && !target) {
    return <Link href={href}>{children}</Link>;
  }

  return <>{children}</>;
};

export const Header = ({ isVehiclePage }) => {
  const { session, isAuthenticated } = useAuth();
  const [loginModalIsOpen, setLoginModalIsOpen] = useState(false);
  usePageChange(() => setLoginModalIsOpen(false));
  const bookmark = useBookmark();

  const openLoginModal = () => {
    setLoginModalIsOpen(true);
  };

  const [menu, setMenu] = useState([
    {
      label: "Veículos",
      href: "/catalogo",
    },
    {
      label: "Como funciona",
      href: "https://ajuda.loopbrasil.com/loop-revenda-geral",
      target: "_blank",
    },
  ]);

  const authMenu = {
    authenticated: [
      {
        label: "Minhas compras",
        href: "/minha-conta/compras?status=analysis,paid_partially,waiting_scheduling,waiting_withdrawal,waiting_onlending,paid,rejected,biggest_offer,offer_beated,pending,reserved,pending_documentation",
        Icon: ShoppingCartIcon,
      },
      {
        label: "Favoritos",
        href: "/minha-conta/favoritos",
        Icon: FavoriteOutlinedIcon,
      },
      {
        label: "Meu cadastro",
        href: "/minha-conta/cadastro/alterar",
        Icon: PersonIcon,
      },
      {
        label: "Nomes de faturamento",
        href: "/minha-conta/nome-faturamento",
        Icon: AssignmentIndIcon,
      },
      {
        label: "Tickets",
        href: "/minha-conta/tickets",
        Icon: ConfirmationNumberIcon,
      },
      {
        label: "Sair",
        onClick: async () => signOut({ callbackUrl: "/" }),
        Icon: ExitToAppIcon,
      },
    ],
    unAuthenticated: [
      {
        label: "Login",
        ...(isVehiclePage ? { onClick: openLoginModal } : { href: "/login" }),
      },
      {
        label: "Cadastro",
        href: "/cadastro/37053ded-f261-4769-b78f-43ed366dbcde",
      },
    ],
  };

  useEffect(() => {
    if (
      session?.groups?.length &&
      !menu.some((item) => item.label === "Ofertas exclusivas")
    ) {
      setMenu((m) => [
        {
          label: "Ofertas exclusivas",
          href: `/catalogo?${session?.groups
            .map(({ label }) => `grupo=${label}`)
            .join("&")}`,
          highlight: true,
        },
        ...m,
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  return (
    <>
      <LoopHeader
        logoSrc="/img/logo-revenda.svg"
        menu={menu}
        bookmark={{
          href: "/minha-conta/favoritos",
          count: bookmark.data.length,
        }}
        authMenu={authMenu}
        authenticated={isAuthenticated}
        linkWrapper={LinkAdapter}
      />
      <LoginModal
        isOpen={loginModalIsOpen}
        onClose={() => setLoginModalIsOpen(false)}
      />
    </>
  );
};

export default Header;
